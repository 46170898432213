var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"PackingEditValidation"},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"lg":"10"}},[_c('b-form-group',{attrs:{"label":"Box number*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Box number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter box number"},model:{value:(_vm.form.box_number),callback:function ($$v) {_vm.$set(_vm.form, "box_number", $$v)},expression:"form.box_number"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"lg":"10"}},[_c('b-form-group',{attrs:{"label":"Seafood Type*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Seafood Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.options},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"lg":"10"}},[_c('b-form-group',{attrs:{"label":"Quality*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"quality","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.options1},model:{value:(_vm.selected1),callback:function ($$v) {_vm.selected1=$$v},expression:"selected1"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"lg":"10"}},[_c('b-form-group',{attrs:{"label":"Grading(g)*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"grading","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.options2},model:{value:(_vm.selected2),callback:function ($$v) {_vm.selected2=$$v},expression:"selected2"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"lg":"10"}},[_c('b-form-group',{attrs:{"label":"Total weight(kg)*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Total weight"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"readonly":""},model:{value:(_vm.form.totalweight),callback:function ($$v) {_vm.$set(_vm.form, "totalweight", $$v)},expression:"form.totalweight"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"lg":"10"}},[_c('b-form-group',{attrs:{"label":"Status*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.options3},model:{value:(_vm.selected3),callback:function ($$v) {_vm.selected3=$$v},expression:"selected3"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('h2',{staticClass:"form_label_class_heading"},[_vm._v("Supliers")]),_c('br'),_c('div',[_c('div',[_c('b-form',{ref:"form",staticClass:"repeater-form",on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain($event)}}},_vm._l((_vm.items),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Suplier Name*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Select Suplier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.options4},model:{value:(item.suplier),callback:function ($$v) {_vm.$set(item, "suplier", $$v)},expression:"item.suplier"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Weight(g)*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Weight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","step":"0.00","placeholder":"Enter Weight"},model:{value:(item.weight),callback:function ($$v) {_vm.$set(item, "weight", $$v)},expression:"item.weight"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Price Rate","label-class":"form_label_class"}},[_c('b-form-input',{attrs:{"readonly":""},model:{value:(item.rate),callback:function ($$v) {_vm.$set(item, "rate", $$v)},expression:"item.rate"}})],1)],1)],1)}),1)],1),_c('br'),_c('div',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"form_submit_button",attrs:{"variant":"none"},on:{"click":_vm.repeateAgain}},[_c('span',{staticClass:"button_text_styles"},[_vm._v("Add Suplier")])])],1)]),_c('br'),_c('h2',{staticClass:"form_label_class_heading"},[_vm._v("Material cost")]),_c('br'),_c('div',[_c('div',[_c('b-form',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight }),on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain($event)}}},_vm._l((_vm.items1),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Description*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter description"},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[(
                          item.description === 'Box' ||
                          item.description === 'box'
                        )?_c('b-form-group',{attrs:{"label":"Box Type*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Box Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter box type"},model:{value:(item.boxtype),callback:function ($$v) {_vm.$set(item, "boxtype", $$v)},expression:"item.boxtype"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):(
                          item.description !== 'Box' ||
                          item.description !== 'box'
                        )?_c('b-form-group',{attrs:{"label":"Quantity*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter quantity"},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Amount*","label-class":"form_label_class"}},[_c('b-form-input',{attrs:{"readonly":"","placeholder":"Enter amount"},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})],1)],1)],1)}),1)],1),_c('br'),_c('div',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"form_submit_button",attrs:{"variant":"none"},on:{"click":function($event){return _vm.repeateAgain1()}}},[_c('span',{staticClass:"button_text_styles"},[_vm._v("Add cost")])])],1)])])],1)],1),_c('br'),_c('br'),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"form_submit_button",attrs:{"type":"submit","variant":"none"},on:{"click":function($event){return _vm.validationPackingEditForm()}}},[_c('span',{staticClass:"button_text_styles"},[_vm._v(" Edit")])])],1),_c('br'),_c('br')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }